.Officer {
    border: 1px solid #3b6cf4;
    padding: 20px;
    border-radius: 6px;
    overflow: hidden;
}

.Officer .address {
    font-weight: bold;
}

.Officer .address .text {
    width: calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.Officer .contact {
    margin-bottom: 20px;
}
