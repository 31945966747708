.LegalOfficerHome .reading-container .Reading,
.LegalOfficerHome .reading-container button {
    margin-left: auto;
    margin-right: auto;
}

.LegalOfficerHome .reading-container .Reading {
    margin-top: -60px;
}

.LegalOfficerHome .reading-container button {
    display: block;
}

.LegalOfficerHome .transactions,
.LegalOfficerHome .collections,
.LegalOfficerHome .withdrawals {
    margin-top: 30px;
}

.LegalOfficerHome .transactions-button,
.LegalOfficerHome .identities-button,
.LegalOfficerHome .collections-button,
.LegalOfficerHome .withdrawals-button {
    position: absolute;
    top: 25px;
    right: 25px;
}

.LegalOfficerHome .identities {
    position: relative;
    margin-top: 30px;
}

@media (max-width: 1350px) {
    .LegalOfficerHome .reading-container .Reading {
        margin-top: 0;
    }
}
