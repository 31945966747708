.RecoveryProcess .alert-activated {
    font-weight: bold;
    padding: 20px;
    border: 1px solid green;
    border-radius: 6px;
    text-align: center;
    margin-bottom: 50px;
}

.RecoveryProcess .recovery-tab-title {
    display: flex;
    width: 200px;
    text-align: left;
    align-items: center;
}

.RecoveryProcess .recovery-tab-title .title {
    flex-grow: 1;
    margin-left: 5px;
}

.RecoveryProcess .recovery-tab-title .size {
    background-color: #e95b5b;
    height: 20px;
    min-width: 20px;
    border-radius: 36px;
    text-align: center;
    line-height: 20px;
}

.RecoveryProcess .NetworkWarning+.alert-activated {
    margin-top: 30px;
}

.RecoveryProcess .tab-content {
    padding: 0;
}

.RecoveryProcess .content {
    padding: 20px;
}

.RecoveryProcess .recovery-process-footer {
    background: linear-gradient(to top, #e95b5b7f, #152665 50%);
    position: relative;
    height: 230px;
    border-radius: 9px;
}

.RecoveryProcess .recovery-process-footer-image {
    position: absolute;
    display: block;
    bottom: 0;
    right: 30px;
}

.RecoveryProcess .asset-name-cell {
    line-height: 40px;
}

.RecoveryProcess .account-protection-container {
    padding-top: 40px;
}
