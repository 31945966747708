.ImportItemDetails {
    padding: 18px 10px;
    width: 100%;
}

.ImportItemDetails .error-message {
    color: #e11a25;
}

.ImportItemDetails pre {
    font-size: 0.9rem;
    font-family: "Montserrat", sans-serif;
    line-break: anywhere;
    white-space: break-spaces;
}

.ImportItemDetails .item-description,
.ImportItemDetails .item-file,
.ImportItemDetails .item-delivery,
.ImportItemDetails .item-tcs,
.ImportItemDetails .item-token,
.ImportItemDetails .item-certificate {
    text-align: left;
}

.ImportItemDetails .item-certificate .url {
    word-break: break-all;
}

.ImportItemDetails .item-certificate .qr {
    text-align: center;
}
