.CertificateCell:nth-child(n+2) {
    padding-left: 30px;
}

.CertificateCell .value {
    margin-bottom: 20px;
    word-wrap: break-word;
}

.CertificateCell.matched {
    border: 2px solid #37ad4b;
}

