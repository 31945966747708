.Toggle {
    content: " ";
    position: relative;
    height: 27px;
    width: 64px;
}

.Toggle.clickable {
    cursor: pointer;
}

.Toggle.white.checked:after {
    background-image: url("../../img/toggle-on-white.svg");
}
.Toggle.white:not(.checked):after {
    background-image: url("../../img/toggle-off-white.svg");
}

.Toggle.black.checked:after {
    background-image: url("../../img/toggle-on-black.svg");
}
.Toggle.black:not(.checked):after {
    background-image: url("../../img/toggle-off-black.svg");
}

.Toggle:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.Toggle.disabled,
.Toggle.clickable.disabled {
    cursor: default;
    opacity: 0.5;
}
