.Gauge {
    position: relative;
}

.Gauge .reading .value-unit {
    display: flex;
    align-items: baseline;
    padding-top: 130px;
}

.Gauge .reading .value {
    display: flex;
    align-items: baseline;
}

.Gauge .reading .value .integer-part {
    font-size: 80px;
    font-weight: bold;
}

.Gauge .reading .value .decimal-part {
    font-size: 30px;
}

.Gauge .reading .unit {
    font-size: 20px;
    padding-left: 5px;
}

.Gauge.linear {
    display: flex;
    align-items: baseline;
}

.Gauge .reading {
    margin-right: 40px;
}

.Gauge .reading .value .integer-part {
    line-height: 1;
}

.Gauge .reading .value-unit {
    padding-top: 0;
}

@media (max-width: 1385px) {
    .Gauge:before {
        width: 200px;
    }
    .Gauge .reading .value .integer-part {
        font-size: 56px;
    }
    .Gauge .reading .value .decimal-part {
        font-size: 21px;
    }
}
