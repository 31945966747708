.LegalEntity {
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
}

.LegalEntity .icon-container {
    display: flex;
    align-items: center;
}

.LegalEntity .icon-container img {
    margin-left: 30px;
    margin-right: 30px;
}

.LegalEntity .text-container {
    padding-right: 30px;
}

.LegalEntity .text-container p {
    margin-bottom: 0;
}

.LegalEntity .text-container p.important {
    text-transform: uppercase;
    font-weight: bold;
}
