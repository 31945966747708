.CreateProtectionRequestFormInitiateRecovery {
    margin-bottom: 40px;
}

.CreateProtectionRequestFormInitiateRecovery .EstimatedFees {
    margin-bottom: 20px;
}

.CreateProtectionRequestFormInitiateRecovery .Button {
    margin-top: 20px;
}
