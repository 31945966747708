.Certificate.container {
    position: relative;
    margin-top: 50px;
    margin-bottom: 40px;
    padding: 70px;
    font-size: 1rem;
    border: 20px solid #3b6cf4;
}

.Certificate.container.with-item-record {
    padding-bottom: 350px;
}

.Certificate .header {
    margin-bottom: 50px;
}

.Certificate .logo {
    height: 140px;
}

.Certificate .header .description {
    margin-top: 50px;
}

.Certificate .background-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    text-align: right;
}

.Certificate .background-icon img {
    width: 70%;
}

.Certificate .folder-icon {
    position: absolute;
    bottom: 60px;
    right: 40px;
}

.Certificate .shield-icon {
    position: absolute;
    top: -70px;
    right: 70px;
}

.Certificate .shield-icon img {
    height: 300px;
}

.Certificate .row.buttons > *:nth-child(n+2) {
    padding-left: 30px;
}

.Certificate .preamble-footer {
    margin-bottom: 20px;
}

.Certificate .legal-officer-row {
    margin-top: 20px;
}

.Certificate pre {
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    line-break: anywhere;
    white-space: break-spaces;
}

.void-frame,
.network-frame,
.not-closed-frame {
    border: 2px solid #e11a25;
    border-radius: 12px;
    padding: 30px;
    margin-top: 30px;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}

.void-stamp {
    position: fixed;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.void-stamp img {
    height: 80vh;
}

.VoidMessage.left {
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.VoidMessage.left .content {
    display: flex;
    justify-content: center;
    width: 100%;
}

.VoidMessage.left .text {
    margin-left: 15px;
}

.supersede-frame {
    border: 2px solid #3b6cf4;
    border-radius: 12px;
    padding: 30px;
    margin-top: 30px;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}

.SupersedeMessage {
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.SupersedeMessage .content {
    display: flex;
    justify-content: center;
    width: 100%;
}

.SupersedeMessage .text {
    margin-left: 15px;
}

.CertificateCheck.container,
.CopyCheck.container {
    padding-left: 0;
    padding-right: 0;
}

.CopyCheck.container {
    margin-top: 50px;
}

.CertificateBox {
    padding-bottom: 200px;
}

.Certificate .timestamp {
    font-weight: normal;
}

.Certificate .file-nature {
    font-weight: normal;
    text-transform: none;
}

.Certificate .separator {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
}

.Certificate .collection-claim-container {
    margin-bottom: 70px;
}

.Certificate .qrcode-container {
    text-align: center;
}

.not-closed-stamp {
    position: fixed;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.not-closed-stamp img {
    height: 70vh;
}

.NotClosedMessage {
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.NotClosedMessage .content {
    display: flex;
    justify-content: center;
    width: 100%;
}

.NotClosedMessage .text {
    margin-left: 15px;
}

@media screen and (max-width: 1400px) {
    .Certificate .folder-icon img {
        width: 470px;
    }
}

@media screen and (max-width: 1200px) {
    .Certificate .shield-icon {
        right: 80px;
    }
}

@media screen and (max-width: 1196px) {
    .Certificate.container {
        padding-bottom: 350px;
    }
}

@media screen and (max-width: 992px) {
    .Certificate .logo {
        height: 80px;
    }
    .Certificate .shield-icon img {
        height: 200px;
    }
    .Certificate .shield-icon {
        top: -60px;
        right: 50px;
    }
    .Certificate.container {
        margin-top: 40px;
        padding: 50px;
        padding-bottom: 350px;
    }
}

@media screen and (max-width: 768px) {
    .CertificateCell:nth-child(n+2) {
        padding-left: calc(var(--bs-gutter-x) * .5);
    }
    .Certificate .row.buttons > *:nth-child(n+2) {
        padding-left: calc(var(--bs-gutter-x) * .5);
    }
    .Certificate .row.buttons {
        text-align: center;
    }
    .Certificate .row.buttons button {
        margin-top: 30px;
    }
    .Certificate .row.buttons a {
        margin-top: 30px;
        display: inline-block;
    }
    .Certificate .background-icon img {
        width: 100%;
    }
    .Certificate .folder-icon {
        right: 0;
    }
    .Certificate .folder-icon img {
        width: 90%;
    }
    .Certificate .header h2 {
        font-size: 20px;
    }
    .Certificate .header h1 {
        font-size: 40px;
    }
}

@media screen and (max-width: 575px) {
    .Certificate.container {
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 40px;
        margin-bottom: 0;
    }
    .Certificate .shield-icon {
        right: 20px;
    }
}

@media screen and (max-width: 485px) {
    .Certificate .shield-icon {
        display: none;
    }
    .Certificate.container {
        margin-top: 0;
    }
    .Certificate.container {
        padding-bottom: 20px;
    }
    .Certificate .folder-icon {
        display: none;
    }
    .Certificate .background-icon {
        display: none;
    }
    .Certificate .logo-container {
        text-align: center;
    }
}

@media screen and (max-width: 350px) {
    .Certificate .header h2 {
        font-size: 20px;
    }
    .Certificate .header h1 {
        font-size: 30px;
    }
}
