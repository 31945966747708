.Transactions .frame-title img {
    margin-left: 20px;
    margin-right: 10px;
    height: 36px;
}

.Transactions .WalletGauge {
    margin-bottom: 10px;
}

.Transactions .from-to-header .from,
.Transactions .from-to-header .to {
    display: inline-block;
    width: 100px;
}

.Transactions .from-to-header .from {
    text-align: right;
}

.Transactions .from-to-header .to {
    text-align: left;
}

.Transactions .from-to-header .from,
.Transactions .from-to-header .to {
    width: calc(50% - 31px);
}

.Transactions .from-to-header .separator {
    display: inline-block;
    width: 62px;
    text-align: center;
}

.Transactions .from-to-cell {
    line-height: 40px;
}

.Transactions .from-to-cell .from,
.Transactions .from-to-cell .to {
    display: inline-block;
    width: calc(50% - 31px);
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
}

.Transactions .from-to-cell .to {
    margin-left: 62px;
    text-align: left;
}

.Transactions .Frame-Wallet .Table .body {
    overflow-y: auto;
    height: calc(100vh - 600px);
    min-height: 65px;
}

.Transactions .Frame-Vault .Table .body {
    overflow-y: auto;
    height: calc(86vh - 620px);
}

.Transactions .gauge-title {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    align-items: center;
}

.Transactions .gauge-container .title {
    margin-bottom: 10px;
}

.Transactions .gauge-title img {
    margin-right: 10px;
}

.Transactions .requests {
    padding-right: 17px;
}
.Transactions .VaultTransferRequests .Table .body {
    overflow-y: auto;
    max-height: 300px;
}
