.FormGroup {
    text-align: left;
}

.FormGroup .form-control {
    border: none;
    border-bottom: 1px solid #3b6cf4;
    font-weight: bold;
    border-radius: 0;
    background-color: transparent;
}

.FormGroup .form-control[readonly] {
    background-color: transparent;
}

.FormGroup .form-control:focus {
    border-bottom: 2px solid #3b6cf4;
    box-shadow: none;
    background-color: transparent;
}

.FormGroup .form-control.is-invalid,
.FormGroup .form-control[readonly].is-invalid {
    background-color: rgba(225, 26, 37, 0.2);
    border: solid 1px #e11a25;
    border-radius: 4px;
    background-image: url("../img/error.svg");
    padding-left: 5px;
}

.FormGroup .form-control.is-invalid:focus {
    box-shadow: none;
}

.FormGroup .invalid-feedback {
    display: block;
    visibility: hidden;
}

.FormGroup .invalid-feedback:after {
    content: '\00a0';
}

.FormGroup .is-invalid ~ .invalid-feedback {
    visibility: visible;
}

.FormGroup .form-check-input:not(.is-invalid) ~ .form-check-label {
    padding-bottom: 20px;
}

.FormGroup .input-group .btn-primary,
.FormGroup .input-group .btn-primary.dropdown-toggle {
    background-color: transparent;
    border: none;
}

.FormGroup .input-group .btn-primary:focus,
.FormGroup .input-group .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0;
}

.FormGroup textarea.form-control {
    border-right: 1px solid #3b6cf4;
    border-left: 1px solid #3b6cf4;
    border-top: 1px solid #3b6cf4;
}

.FormGroup textarea.form-control:focus {
    border-right: 2px solid #3b6cf4;
    border-left: 2px solid #3b6cf4;
    border-top: 2px solid #3b6cf4;
}

.FormGroup p {
    font-weight: bold;
    border-bottom: 1px solid #3b6cf4;
    padding: 0.375rem 0.75rem;
    margin: 0;
}
