.IconTextRow {
    display: flex;
    width: 100%;
}

.IconTextRow .text {
    margin-left: 15px;
    width: 100%;
}

.Dashboard .PrimaryArea .IconTextRow p.text-title {
    font-size: 18px;
    font-weight: bold;
}
