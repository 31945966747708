.ArchiveButton {
    height: 40px;
}

.ArchiveButton img {
    margin-right: 10px;
}

.Dialog .modal-body .check-risk {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
}

.Dialog .modal-body .check-risk .Checkbox {
    margin-right: 10px;
}
