.Eye {
    content: " ";
    position: relative;
    height: 37px;
    width: 50px;
}

.Eye.clickable {
    cursor: pointer;
}

.Eye.checked:after {
    background-image: url("../../img/eye.svg");
}
.Eye:not(.checked):after {
    background-image: url("../../img/eye-slash.svg");
}

.Eye:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.Eye.disabled,
.Eye.clickable.disabled {
    cursor: default;
    opacity: 0.5;
}
