.Table .header {
    text-transform: uppercase;
    font-size: 0.7rem;
}

.Table .header .Col {
    padding: 10px;
}

.Table .body .Row {
    font-size: 0.9rem;
    padding: 0;
    font-weight: bold;
    margin-bottom: 10px;
}

.Table.double-space-rows .body .Row {
    margin-bottom: 20px;
}

.Table .body .Row .Col {
    position: relative;
    padding: 10px;
}

.Table.constrained-row-height .body .Row .Col {
    height: 60px;
    overflow: hidden;
}

.Table .body .Row .Col:first-child {
    padding-left: 10px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.Table .body .Row .Col:last-child {
    padding-right: 10px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.Table .body .Row .Col.split-after {
    position: relative;
}

.Table .body .Row .Col.split-after > * {
    position: relative;
    z-index: 1;
}

.Table .body .Row .Col.split-after::before {
    content: " ";
    position: absolute;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    top: 0;
    left: 0;
    height: calc(100%);
    width: calc(100% - 2px);
}

.Table .body .Row .Col.split-after + .Col {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.Table .body .Row .cell {
    display: flex;
}

.Table .regular-cell {
    font-size: 0.9rem;
    line-height: 40px;
    overflow: hidden;
}

.Table .regular-cell.small-text {
    font-size: 0.7rem;
    line-height: 40px;
}

.Table .regular-cell.two-lines {
    line-height: 20px;
}

.Table .regular-cell.overflowing {
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Table .regular-cell span {
    line-height: 1;
}

.Table .Col.has-details .regular-cell {
    margin-right: 10px;
}

.Table .date-cell {
    font-size: 0.9rem;
    line-height: 20px;
    overflow: hidden;
    padding-left: 10px;
    text-align: center;
}

.Table .empty-message {
    flex-grow: 1;
    text-align: center;
    line-height: 40px;
    font-weight: normal;
    font-style: italic;
}

/**
 * Row details
 */
.Table .body .Row.details .Col {
    height: auto;
    padding: 0;
}

.Table .body .Row.has-details {
    margin-bottom: 2px;
}

.Table .body .Row .ShowDetailsButton {
    position: absolute;
    top: 18px;
    right: 10px;
    opacity: 1;
    transition: opacity;
    cursor: pointer;
}

.Table .body .Row .ShowDetailsButton.expanded img {
    transform: rotate(180deg);
}

.Table .body .Row .ShowDetailsButton:hover {
    opacity: 0.6;
}

.Table .body .Row.details {
    height: 0;
    overflow: hidden;
    transition: height;
    border-radius: 6px;
}

.Table .body .Row.details.expanded {
    height: auto;
    overflow: hidden;
}

.Table .action-cell {
    display: flex;
    justify-content: flex-end;
    line-height: 40px;
}
