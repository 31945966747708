.modal-content {
    z-index: 1060;
}

.Button.btn {
    padding: 9px 20px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 0.9rem;
}

.Button.btn.slim {
    padding-top: 0;
    padding-bottom: 0;
}

.Button.btn.narrow {
    padding-left: 0;
    padding-right: 0;
}

.Button.btn.btn-secondary:hover:before {
    background: linear-gradient(to top, #3b6cf4, #6050dc);
}

.Button.btn.btn-primary {
    background: linear-gradient(to bottom, #3b6cf4, #6050dc);
    border: none;
}

.Button.btn.btn-primary:hover {
    background: linear-gradient(to top, #3b6cf4, #6050dc);
}

.Button.btn.btn-primary a,
.Button.btn.btn-secondary a,
.Button.btn.btn-warning a,
.Button.btn.btn-recovery a {
    color: white;
    text-decoration: none;
}

.Button.btn.btn-primary a:hover,
.Button.btn.btn-secondary a:hover,
.Button.btn.btn-warning a:hover,
.Button.btn.btn-recovery a:hover {
    text-decoration: none;
}

.Button.btn.btn-primary:focus,
.Button.btn.btn-secondary:focus,
.Button.btn.btn-warning:focus,
.Button.btn.btn-recovery:focus {
    box-shadow: none;
}

.Button.btn.btn-warning {
    background-color: #ff8a5c !important;
    border: none;
    color: white;
}

.Button.btn.btn-warning:hover {
    text-decoration: underline;
}

.Button.btn.btn-danger {
    background: linear-gradient(to bottom, #ea1f46, #9b0d28);
    border: none;
}

.Button.btn.btn-danger:hover {
    background: linear-gradient(to bottom, #9b0d28, #ea1f46);
}

.Button.btn.btn-recovery {
    background: linear-gradient(to bottom, #e95b5b, #6050dc);
    border: none;
    color: white;
}

.Button.btn.btn-recovery:hover {
    background: linear-gradient(to top, #e95b5b, #6050dc);
}

.Button.btn.btn-salmon {
    background-color: #e95b5b;
    border: none;
    color: white;
}

.Button.btn.btn-salmon:hover {
    background-color: #e95b5b;
    text-decoration: underline;
}

.Button.btn.btn-danger-outline {
    border: 1px solid #e11a25;
}

.Button .text {
    margin-left: 15px;
}

.Button.btn.btn-danger-flat {
    background-color: #ea1f46;
    border: none;
}

.Button.btn.btn-danger-flat:hover {
    background-color: #ea1f46;
    opacity: 0.8;
}

.Button.btn.btn-none {
    margin: 0;
    padding: 0;
}

.Button.btn.btn-polkadot:hover {
    opacity: 0.8;
}

.Button.btn-none:disabled {
    border: none;
}
