.AddressSwitcher {
    height: 95px;
    width: 450px;
    display: flex;
}

.AddressSwitcher .dropdown {
    width: 100%;
}

.AddressSwitcher .dropdown-menu {
    overflow-y: auto;
    max-height: calc( 100vh - 160px );
    background-image: none;
}

#address-switcher-toggle {
    width: 100%;
    height: 95px;
    background-color: transparent;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border: none;
    border-radius: 0;
    text-align: left;
    padding-bottom: 20px;
}

#address-switcher-toggle:focus {
    box-shadow: none;
}

#address-switcher-toggle .address-data {
    display: inline-block;
    width: calc(100% - 20px);
}

.AddressSwitcher .dropdown-menu {
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.AddressSwitcher.dark .dropdown-menu {
    border-top: 1px solid white;
}

.AddressSwitcher.light .dropdown-menu {
    border-top: 1px solid black;
}

.AddressSwitcher .dropdown-menu .dropdown-item {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
}

.AddressSwitcher .dropdown-menu .dropdown-item:focus, .AddressSwitcher .dropdown-menu .dropdown-item:hover {
    background-color: transparent;
}

.AddressSwitcher .dropdown-menu .dropdown-item.logout {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.AddressSwitcher.light .dropdown-toggle:after {
    color: black;
}
