.CollectionLimitsForm .limit-container {
    margin-left: 40px;
    margin-right: 40px;
}

.CollectionLimitsForm .can-upload-check-container {
    width: 300px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}

.CollectionLimitsForm .DatePicker input {
    color: #3b6cf4;
}
