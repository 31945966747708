.TransactionStatusCell {
    margin-top: 5px;
}

.TransactionStatusCellDetails {
    margin-top: 15px;
}

.TransactionStatusCellDetails .Alert {
    margin-left: 10px;
    padding-bottom: 0;
}

.TransactionStatusCellDetails > .Row {
    margin-bottom: 0 !important;
}
