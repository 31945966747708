.Logo {
    padding: 40px;
    padding-right: 20px;
    height: 160px;
    z-index: 1;
}

.Logo .image-and-slogan {
    display: flex;
    height: 80px;
}

.Logo .image {
    height: 100%;
    margin-right: 20px;
}

.Logo .image img {
    height: 100%;
}

.Logo .slogan {
    display: flex;
    font-size: 14px;
    flex-direction: column;
    justify-content: flex-end;
}
