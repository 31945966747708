.LocItemDetail {
    padding: 5px 5px;
}

.LocItemDetail .label {
    font-weight: bold;
    font-size: 0.9rem;
    margin-right: 5px;
    flex-shrink: 0;
}

.LocItemDetail .value {
    font-weight: normal;
    font-size: 0.9rem;
}

.LocItemDetail .value pre {
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
}

.LocItemDetail .value-container {
    text-overflow: ellipsis;
    overflow: hidden;
}

.LocItemDetail .copy-paste-container {
    flex-shrink: 0;
}
