.StatementOfFactsButtonDropdownItem {
    display: block;
    text-decoration: none;
}

.Dashboard .StatementOfFactsButtonDropdownItem a {
    color: white;
    margin-bottom: 0;
    line-height: 1.1rem;
}
