.LegalOfficerInstructions.logion-loc-tip-container {
    margin-bottom: 30px;
}

.LegalOfficerInstructions .upper-action-bar {
    margin-left: 20px;
    width: 450px;
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

.LegalOfficerInstructions .upper-action-bar .create-logion-transaction-loc-button {
    height: 42px;
}
