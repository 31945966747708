.Detail {
    padding: 18px 10px;
}

.Detail .label {
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 0.7rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Detail .value {
    font-size: 0.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
