.UserHome .character-container img {
    position: absolute;
    right: 0;
    bottom: 0;
}

.UserHome .reading-container .Reading,
.UserHome .reading-container button {
    margin-left: auto;
    margin-right: auto;
}

.UserHome .reading-container .Reading {
    margin-top: -60px;
}

.UserHome .reading-container button {
    display: block;
}

.UserHome .shortcuts {
    position: relative;
    margin-top: 30px;
    padding-bottom: 284px;
}

.UserHome .shortcuts-character-container {
    position: absolute;
    bottom: -30px;
    left: 40px;
}

@media (max-width: 1221px) {
    .UserHome .shortcuts-character-container {
        left: 20px;
    }
}

@media (max-width: 1558px) {
    .UserHome .reading-container .Reading {
        margin-top: 0;
    }
}

@media (max-width: 1350px) {
    .UserHome .character-container img {
        width: 150px;
    }

    .UserHome .shortcuts {
        padding-bottom: 160px;
    }

    .UserHome .shortcuts-character-container img {
        height: 300px;
    }    
}
