.ItemFileDetails {
    width: calc(100% - 60px);
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
}

.ItemFileDetails .Table .header .Col {
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: normal;
}

.ItemFileDetails .Row.positive-match {
    border: 2px #37ad4b dashed;
}

.ItemFileDetails .Row.negative-match {
    border: 2px #e11a25 dashed;
}
