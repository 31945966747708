.Dashboard {
    height: 100vh;
    position: relative;
    display: flex;
}

.Dashboard .Sidebar {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 280px;
}

.Dashboard .Sidebar .MenuArea {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Dashboard .BasePane {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 10px;
    overflow: hidden;
    flex: 1;
}

.Dashboard .ContentArea {
    flex: 1;
    overflow-y: auto;
}

.Dashboard .PrimaryArea > [class*="row"] {
    margin-bottom: 40px;
}

.Dashboard .PrimaryArea > [class*="row"]:last-child {
    margin-bottom: 0;
}

.Dashboard .primary-area-col {
    padding-left: 0;
    padding-right: 0;
}

.Dashboard .PrimaryArea {
    position: relative;
    font-size: 0.9rem;
    overflow-x: hidden;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
}

.Dashboard .SecondaryArea {
    font-size: 0.9rem;
    overflow-x: hidden;
    padding-top: 23px;
    padding-left: 20px;
    padding-right: 25px;
    padding-bottom: 30px;
}

.Dashboard .BasePaneHeader {
    display: flex;
}

.Dashboard .BasePaneHeader .TitleArea {
    flex: 1;
}

.Dashboard .BasePaneHeader .AddressSwitcherArea {
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 20px;
    padding-left: 20px;
}

.Dashboard .PrimaryArea h1,
.Dashboard .SecondaryArea h1 {
    font-size: 1.2rem;
    font-weight: bold;
    padding-top: 30px;
    padding-bottom: 60px;
}

.Dashboard .PrimaryArea h2,
.Dashboard .SecondaryArea h2 {
    font-size: 1.1rem;
    font-weight: bold;
}

.Dashboard .PrimaryArea h3,
.Dashboard .SecondaryArea h3 {
    font-size: 1rem;
    padding-bottom: 20px;
}

.Dashboard .PrimaryArea p,
.Dashboard .SecondaryArea p {
    font-size: 0.9rem;
    font-weight: normal;
}

.Dashboard .Titles {
    padding-left: 40px;
    height: 160px;
}

.Dashboard .Titles h1 {
    font-size: 1.5rem;
    font-weight: bold;
    padding-top: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Dashboard .Titles h1 .MenuIcon {
    display: inline-block;
}

.Dashboard .Titles h1 .MenuIcon img {
    vertical-align: top;
    max-height: 36px;
}

.Dashboard .Titles h2 {
    font-size: 1.2rem;
    padding-top: 20px;
    margin-left: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Dashboard .back-button {
    margin-top: 30px;
    font-size: 18px;
    font-weight: bold;
}

.Dashboard .back-button img {
    margin-right: 10px;
}

.Dashboard .TopArea {
    font-size: 0.9rem;
    overflow-x: hidden;
    padding-top: 23px;
    padding-left: 30px;
    padding-right: 40px;
    padding-bottom: 30px;
}

.Dashboard .Notifications {
    position: absolute;
    top: 130px;
    right: 35px;
    width: 280px;
}

@media (max-width: 1350px) {
    .Dashboard .Sidebar {
        width: 260px;
    }
}
