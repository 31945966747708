.CollectionLocItemChecker {
    margin-top: 20px;
}

.CollectionLocItemChecker .item-id-input {
    display: inline-block;
    width: calc(100% - 32px);
}

.CollectionLocItemChecker .clear-button {
    cursor: pointer;
    margin-left: 7px;
}

.CollectionLocItemChecker .clear-button:hover {
    opacity: 0.5;
}

.CollectionLocItemChecker .Button {
    margin-left: 20px;
}

.CollectionLocItemChecker .IconTextRow .text {
    width: calc(100% - 80px);
}

.CollectionLocItemChecker .CheckResultFeedback {
    margin-top: 20px;
}

.CollectionLocItemChecker .CheckResultFeedback .label-positive {
    color: #37ad4b;
    font-weight: bold;
}

.CollectionLocItemChecker .CheckResultFeedback .label-negative {
    color: #ea1f46;
    font-weight: bold;
}

.CollectionLocItemChecker .CheckResultFeedback .Col {
    padding-right: 20px;
    min-width: 65px;
}

.CollectionLocItemChecker .CheckResultFeedback .url-header {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.CollectionLocItemChecker div.IconTextRow div.text div.FormGroup div div.Row div.Col {
    width: 50rem;
}

.CollectionLocItemChecker .url-copy-paste-container {
    display: flex;
}

.CollectionLocItemChecker .url-copy-paste-container .url-container {
    width: calc(100% - 20px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.CollectionLocItemChecker .url-copy-paste-container button {
    flex-shrink: 0;
}

.CollectionLocItemChecker .matched {
    border: 2px solid #37ad4b;
}

.CollectionLocItemChecker .buttons {
    display: flex;   
}
