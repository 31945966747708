.CheckFileResult {
    position: relative;
    border-top: 2px solid #3B6CF4;
    margin-top: 30px;
    padding-top: 30px;
    margin-left: -82px;
    padding-left: 20px;
    margin-right: -20px;
    padding-right: 20px;
}

.CheckFileResult p,
.CheckFileResult ul {
    margin-left: 62px;
}

.CheckFileResult img {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 100px;
    height: 100px;
}
