.RecoveryDetails .main-frame {
    padding-top: 20px;
    margin-bottom: 50px;
}

.RecoveryDetails .main-frame > .row:first-child {
    position: relative;
}

.RecoveryDetails h3 {
    text-align: center;
    font-weight: bold;
    padding-bottom: 0 !important;
}

.RecoveryDetails .Alert {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.RecoveryDetails .AccountInfoFrom {
    margin-right: 10px;
}

.RecoveryDetails .ButtonGroup {
    margin-top: 40px;
}
