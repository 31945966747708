.DashboardCertificate .frame-container {
    margin-top: 30px;
}

.DashboardCertificate pre {
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    line-break: anywhere;
    white-space: break-spaces;
}

.DashboardCertificate .Row.matched {
    border: 2px solid #37ad4b;
}
