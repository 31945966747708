.BalanceDetails {
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.BalanceDetails .detail {
    display: flex;
    justify-content: space-between;
}

.BalanceDetails .label {
    display: inline-block;
    width: 130px;
}
