.MenuIcon {
    margin-right: 15px;
    border-radius: 10px;
    display: block;
    height: 32px;
    width: 32px;
    border-radius: 5px;
    margin-right: 15px;
    flex-shrink: 0;
    flex-grow: 0;
}

.MenuIcon img {
    width: 100%;
}
