.PersonalInfo .title {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.PersonalInfo .Detail .label {
    font-weight: bold;
    font-size: 0.9rem;
}
