.ImportItems .import-all-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    margin-right: 53px;
}

.ImportItems .regular-cell .Alert.danger {
    line-height: 1.1;
}

.ImportItems .regular-cell .upload-error {
    margin-left: 15px;
}
