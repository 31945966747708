.IdentityLocRequest form {
    margin-top: 50px;
}

.IdentityLocRequest .agree-submit {
    text-align: center;
    margin-top: 50px;
}

.IdentityLocRequest .agree-submit button[type=submit] {
    margin-top: 30px;
}

.IdentityLocRequest .company-frame {
    margin-top: 30px;
}

.IdentityLocRequest .company-check-container {
    margin-top: 30px;
}

.IdentityLocRequest .company-name-container {
    margin-top: 30px;
}

.IdentityLocRequest .sponsorship-id {
    margin-top: 30px;
}
