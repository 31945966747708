.SecretDownloadPage {
    background-color: #0c163d;
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: 100vh;
}

.SecretDownloadPage .container > .row {
    margin-bottom: 30px;
}

.SecretDownloadPage .container > .row:last-child {
    margin-bottom: 0;
}

.SecretDownloadPage .alert-container {
    display: flex;
    justify-content: center;
}

.SecretDownloadPage .success-frame {
    text-align: center;
}

.SecretDownloadPage .success-icon {
    margin-bottom: 30px;
}

.SecretDownloadPage .secret-value {
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
}
