.Select.is-invalid .Select__indicator,
.Select.is-invalid .Select__indicator:hover {
    color: #e11a25;
}

.Select .Select__control {
    border-width: 1px;
}

.Select.is-invalid .Select__control,
.Select.is-invalid .Select__control:hover {
    border-color: #e11a25;
}

.Select .Select__control:hover {
    border-color: #3b6cf4;
}

.Select .Select__option:hover {
    background-color: #ffffff0f;
}

.Select .Select__indicators {
    color: #3b6cf4;
}

.Select .Select__indicator-separator {
    visibility: hidden;
}
