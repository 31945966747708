.ComparableField label {
    margin-top: 10px;
    margin-bottom: 0;
    text-align: left;
}

.ComparableField input {
    font-size: 14px;
    padding: 0;
    margin: 0;
}
