.AccountAddress {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.AccountAddress .icon {
    height: 75px;
    width: 75px;
    border-radius: 36px;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 45px;
    font-weight: bold;
    color: white;
    padding-left: 18px;
    flex-shrink: 0;
}

.AccountAddress .text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 75px;
    width: calc(100% - 180px);
}

.AccountAddress.disabled {
    cursor: default;
}

.AccountAddress.disabled .icon,
.AccountAddress.disabled .text {
    opacity: 0.5;
}

.AccountAddress .text .balance {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.AccountAddress .text .balance .reserved {
    opacity: 0.5;
}

.AccountAddress .text .name {
    font-size: 1.1rem;
    font-weight: bold;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.AccountAddress .text .address {
    font-size: 0.9rem;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.AccountAddress .login {
    padding-left: 20px;
    padding-right: 20px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.AccountAddress .login .login-button {
    height: 20px;
    width: 20px;
    border-radius: 10px;
    line-height: 1;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.AccountAddress .login .login-button img {
    margin-left: 3px;
}
