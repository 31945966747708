.LocPane .closed-icon-container {
    position: relative;
}

.LocPane .closed-icon-container .closed-icon {
    position: absolute;
    top: -55px;
    right: 20px;
}

.LocPane .Row.matched {
    border: 2px solid #37ad4b;
}

.Dashboard .LocPane .PrimaryArea p.frame-title {
    font-size: 18px;
    font-weight: bold;
}
