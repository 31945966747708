.Login {
    min-height: 100vh;
    background: linear-gradient(0deg, #9f1278 0%, #3b6cf4 100%);
    display: flex;
    position: relative;
    font-family: "Montserrat", sans-serif;
    color: white;
}

.Login:before {
    content: " ";
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url("./img/login-waves.svg");
    background-size: cover;
    z-index: 0;
}

.Login .column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Login h1 {
    font-size: 2.1rem;
    font-weight: bold;
    margin-top: 30px;
    text-align: center;
}

.Login h2 {
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 25px;
}

.Login .chooser {
    text-align: center;
    position: relative;
    z-index: 1;
}

.Login .accounts-container {
    border-radius: 6px;
    height: 335px;
    background-color: rgba(255, 255, 255, 0.5);
    overflow-y: auto;
    margin-bottom: 30px;
    padding: 40px;
    margin-left: 60px;
    margin-right: 60px;
}

.Login .extensions-container {
    text-align: center;
    position: relative;
    z-index: 1;
    padding-top: 3rem;
    padding-bottom: 2rem;
}

.Login .extensions-container .metamask img {
    margin-right: 10px;
}

.Login .recovery-container {
    text-align: center;
    position: relative;
    z-index: 1;
    padding-top: 3rem;
    padding-bottom: 2rem;
}

.Login .left-character {
    position: absolute;
    bottom: 0;
    left: 60px;
}

.Login .right-character {
    position: absolute;
    bottom: 0;
    right: 60px;
}

.Login .account {
    display: flex;
    color: #1b307a;
    padding-bottom: 55px;
    align-items: center;
}

.Login .account:last-child {
    padding-bottom: 0;
}

.Login .account .icon {
    height: 75px;
    width: 75px;
    border-radius: 36px;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 45px;
    font-weight: bold;
    color: white;
    background-color: #3b6cf4;
}

.Login .account .name-address {
    text-align: left;
    font-weight: bold;
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Login .account .name {
    font-size: 1.7rem;
}

.Login .account .address {
    font-size: 0.9rem;
}
