.StaticLabelValue {
    text-align: left;
    margin-bottom: 21px;
}

.StaticLabelValue .label {
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.StaticLabelValue .value {
    overflow: hidden;
    text-overflow: ellipsis;
}

.StaticLabelValue.form-style .label {
    font-weight: normal;
}

.StaticLabelValue.form-style .value {
    font-weight: bold;
}
