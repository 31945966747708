.Alert {
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: bold;
    border-radius: 10px;
}

.Alert.slim {
    padding-top: 0;
    padding-bottom: 0;
}
