.DraftLocInstructions {
    margin-bottom: 30px;
    min-height: 177px;
}

.DraftLocInstructions .project-type {
    text-align: center;
}

.DraftLocInstructions .project-type p {
    margin-top: 10px;
}

.DraftLocInstructions .Frame {
    height: 100%;
}
