.Dialog {
    backdrop-filter: blur(2px);
}

.Dialog .modal-dialog {
    border-radius: 5px;
    border: none;
}

.Dialog .modal-content {
    border: none;
    border-radius: 5px;
    border-style: solid;
    text-align: center;
    border-color: #e6007a;
}

.Dialog .modal-body {
    padding: 20px;
}

.Dialog .modal-footer {
    border: none;
    justify-content: center;
    padding: 0;
    padding-bottom: 30px;
}

.Dialog .modal-footer > * {
    margin-left: 12px;
    margin-right: 12px;
}

.Dialog .modal-content h1 {
    font-weight: bold;
    font-size: 28px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Dialog .modal-content h2 {
    font-weight: bold;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Dialog .modal-content h3 {
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Dialog .modal-content h4 {
    font-weight: bold;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
}

.Dialog.content-hidden .modal-content {
    display: none;
}

.Dialog .modal-body .row {
    margin-left: 0;
    margin-right: 0;
}
