.Reading {
    width: 300px;
    text-align: center;
}

.Reading .value-unit {
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.Reading .value {
    display: flex;
    align-items: baseline;
}

.Reading .value .integer-part {
    font-size: 80px;
    font-weight: bold;
}

.Reading .value .decimal-part {
    font-size: 30px;
}

.Reading .unit {
    font-size: 20px;
    padding-left: 5px;
}
