.Spacer {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    justify-content: center;
    align-items: center;

    display: flex;
}

