.Frame {
    position: relative;
    padding: 20px;
    border-radius: 10px;
}

.Frame.disabled:before {
    position: absolute;
    content: '';
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: 1;
    background-color: black;
    opacity: 0.3;
    border-radius: 10px;
}

.Frame.full-height {
    min-height: calc(100vh - 200px);
}

.Frame .title-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px;
}

.Frame .title {
    font-size: 18px;
    font-weight: bold;
}

.Frame.fill-height {
    height: 100%;
}
