.WalletGauge .actions {
    display: flex;
    justify-content: center;
}

.WalletGauge .actions button:nth-child(n+2) {
    margin-left: 25px;
}

.WalletGauge {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.WalletGauge .actions {
    margin-bottom: 10px;
    flex-shrink: 0;
    height: 36px;
}

.TransferDialog .EstimatedFees {
    margin-top: 20px;
    margin-bottom: 20px;
}
