@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap');

$font-family-sans-serif: 'Montserrat', sans-serif;
$font-family-monospace: "Source Code Pro", monospace !default;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1400px
);


@import "~bootstrap/scss/bootstrap";

body, html {
    height: 100%;
    width: 100%;
}

html {
    font-size: 16px;
}

#root {
    height: 100%;
    width: 100%;
}

@media (max-width: 1350px) {
    html {
        font-size: 14px;
    }
}

.spinner-border.text-polkadot {
    color: #e6007a;
}

.dropdown-menu {
    background: linear-gradient(to bottom, #3b6cf4, #6050dc);
}

.dropdown-item {
    color: white;
    font-size: 0.9rem;
}

.dropdown-item:hover, .dropdown-item:focus {
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
}