.MenuItem {
    display: block;
    height: 56px;
    font-size: 1rem;
    font-weight: bold;
    padding-left: 40px;
    padding-right: 40px;
    border-left-style: solid;
    border-left-width: 3px;
    border-left-color: transparent;
    text-decoration: none;
}

.MenuItem.active {
    font-weight: bolder;
    border-left-color: #e6007a;
}

.MenuItem .text-wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: hidden;
    align-items: center;
}

.MenuItem .text-wrapper .text {
    display: block;
}
