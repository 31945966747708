#NominateButton {
    padding: 4px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#NominateButton img {
    margin-right: 10px;
}

#NominateButton .Toggle {
    margin-left: 10px;
}
