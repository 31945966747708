.ButtonGroup {
    width: 100%;
    text-align: center;
}

.ButtonGroup .Button,
.ButtonGroup .Button-container {
    margin-left: 25px;
}

.ButtonGroup.narrow .Button,
.ButtonGroup.narrow .Button-container {
    margin-left: 5px;
}

.ButtonGroup .Button:first-child,
.ButtonGroup .Button-container:first-child {
    margin-left: 0;
}

.ButtonGroup .btn-group > .btn:not(:last-child),
.ButtonGroup .btn-group > .btn:not(:first-child) {
    border-radius: 6px;
}

.ButtonGroup .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.ButtonGroup .btn-group > .btn-group:not(:last-child) > .btn {
    border-radius: 6px;
}

.ButtonGroup .btn-group > .btn:not(:first-child),
.ButtonGroup .btn-group > div:not(:first-child),
.ButtonGroup .btn-group > .btn-group:not(:first-child),
.ButtonGroup .btn-group > .dropdown:not(:first-child),
.ButtonGroup .btn-group > :not(.btn-check:first-child) + .btn,
.ButtonGroup .btn-group > .btn-group:not(:first-child) {
    margin-left: 25px;
}

.ButtonGroup.narrow .btn-group > .btn:not(:first-child),
.ButtonGroup.narrow .btn-group > .btn-group:not(:first-child),
.ButtonGroup.narrow .btn-group > .dropdown:not(:first-child),
.ButtonGroup.narrow .btn-group > :not(.btn-check:first-child) + .btn,
.ButtonGroup.narrow .btn-group > .btn-group:not(:first-child) {
    margin-left: 5px;
}

.ButtonGroup.left {
    text-align: left;
}

.ButtonGroup.right {
    text-align: right;
}
