.CopyPasteButton.Button.btn.big {
    padding: 4px;
    padding-right: 5px;
    padding-left: 5px;
    height: 40px;
    width: 40px;
}

.CopyPasteButton.Button.btn.big img {
    height: 20px;
}

.CopyPasteButton.Button.btn.small {
    margin-left: 15px;
    margin-right: 10px;
    height: 21px;
    width: 21px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 1px;
    padding-left: 2px;
}

.CopyPasteButton.Button.btn.small img {
    margin-top: -4px;
    height: 0.8rem;
}

.CopyPasteButton.Button.btn.medium {
    margin-left: 15px;
    height: 24px;
    width: 24px;
    padding-top: 0;
    padding-right: 4px;
    padding-left: 4px;
}

.CopyPasteButton.Button.btn.medium img {
    margin-top: -1px;
    height: 1rem;
}
