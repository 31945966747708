.FileSelectorButton.only-button {
    display: inline-block;
}

.FileSelectorButton.only-button input {
    display: none;
}

.FileSelectorButton .Button {
    display: block;
    margin-right: auto;
    margin-top: 10px;
}

.FileSelectorButton.only-button .Button {
    margin-top: 0;
    margin-right: 0;
}
