.StatementOfFactsSummary p {
    text-align: left;
}

.StatementOfFactsSummary .Step1 {
    margin-bottom: 20px;
}

.StatementOfFactsSummary .Step2 {
    margin-bottom: 20px;
}
