.LocItemDetails .frame {
    margin: 20px 10px;
    padding: 20px;
    border: 2px solid #e6007a;
}

.LocItemDetails .frame .frame-title {
    color: #e6007a;
    margin-bottom: 20px;
    text-align: left;
    font-weight: bold;
}

.LocItemDetails .linked-loc .label,.LocItemDetails .linked-loc .value {
    line-height: 32px;
}

.LocItemDetails.claim {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.LocItemDetails.claim > div {
    text-align: center;
}

.LocItemDetails .separator {
    width: calc(100% + 40px);
    height: 2px;
    background-color: #e6007a;
    margin-left: -20px;
    margin-top: 20px;
    margin-bottom: 20px;
}
