.EstimatedFees.centered-table table {
    margin-left: auto;
    margin-right: auto;
}

.EstimatedFees td {
    padding-left: 10px;
    padding-right: 10px;
}

.EstimatedFees td:first-child {
    font-weight: bold;
    text-align: left;
}

.EstimatedFees td:nth-child(2) {
    text-align: right;
    min-width: 100px;
}

.EstimatedFees td:nth-child(3) {
    text-align: left;
    min-width: 150px;
}
