.AbsoluteLogo {
    position: absolute;
    top: 40px;
    left: 40px;
    display: flex;
    align-items: flex-end;
}

.AbsoluteLogo p {
    margin: 0;
    margin-left: 25px;
    font-size: 16px;
    color: white;
}
