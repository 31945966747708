.SelectLegalOfficer {
    width: 520px;
    max-width: 100%;
}

.SelectLegalOfficer .form-group {
    margin-bottom: 5px;
}

.SelectLegalOfficer .icon-status {
    margin-top: 40px;
}

.Dashboard .ContentArea .SelectLegalOfficer > .row {
    margin-bottom: 0;
}
